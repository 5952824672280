const originalMetrics = {
  sampleWeight: "g",
  averageWeight: "g",
  biomass: "g"
}

export const metricConvert = (
  cardName: string,
  values: (number|null)[] | undefined | null,
  targetMetric: string|null
) => {
  if (targetMetric === null) return values
  if (values && Object.keys(originalMetrics).includes(cardName)) {
    if (targetMetric.includes("kg")) {
      return values.map((value) => value ? value / 1000 : null)
    }
    return values
  } else return values
}

function  roundSig(num: number, sig: number = 5): string {
  const numCount = Math.log(num) * Math.LOG10E + 1 | 0
  const fixNum =
    numCount <= 1 ? sig - 1 :
    numCount >= sig ? 0 :
    sig - numCount
  if (fixNum === 0) return Math.round(num).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
  else return parseFloat(num.toFixed(fixNum)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
}

const nonWeightTargets: string[] = ["tankVolume", "nh3", "no2", "do", "ph", "waterLevel", "temperature", "count", "kFactor", "fcr",
"DEAD", "OUT", "IN", "TIMES", "RATIO", "MAX", "feeding", "RADIUS", "DEPTH", ]
const weightTargets: string[] = ["sampleWeight", "averageWeight", "stockingDensity", "biomass", "AMOUNT"]
// const weightMetrics: string[] = ["g", "kg", "kg/m³", "g/m³"]
// const nonWeightMetrics: string[] = ["mg/L", "%", "m³", "m", "°C"]

function targetSwitch(target: string): string {
  switch (target) {
    case "AVERAGE WEIGHT": return "averageWeight"
    case "COUNT": return "count"
    case "TOTAL BIOMASS": return "biomass"
    case "DENSITY": return "stockingDensity"
    case "FEED AMOUNT": return "feeding"
  }
  return target
}

function metricSwitch(metric: string | null | undefined, inc: boolean=false): string {
  if (metric===null || metric===undefined) return ""
  if (inc){
    switch (metric){
      case "g": return "kg"
      case "g/m³": return "kg/m³"
    }
  }
  return metric
}

export function convertMetric (
  value: number | null | undefined | string,
  target: string,
  metric: string | null | undefined,
  sig: number = 5
): {val: string, metric: string} {
  if (typeof value === "string") return {val: value, metric: metricSwitch(metric)}
  const convertedTargetName = targetSwitch(target)
  if (value === null || value === undefined) {
    return {val: "---", metric: metricSwitch(metric)}
  } else if (nonWeightTargets.includes(convertedTargetName)) {
    return {val: roundSig(value, sig), metric: metricSwitch(metric)}
  } else if (weightTargets.includes(convertedTargetName)){
    if (target !== "feeding"){
      // if (value >= 1000) return {val: roundSig(value / 1000, sig), metric: metricSwitch(metric, true)}
      // else return {val: roundSig(value, sig), metric: metricSwitch(metric)}
      return {val: roundSig(value / 1000, sig), metric: metricSwitch(metric, true)}
    }
  }
  return {val: value.toLocaleString(), metric: metricSwitch(metric)}
}