import {ChangeEvent, FC, KeyboardEvent, useState} from "react";
import WhiteLogo from "../../assets/logo/fishscopelogo.jpg"
import {Button} from "@nextui-org/react";
import {useNavigate} from "react-router-dom";
import {login} from "../../api/axiosAuthorization";
import {isAxiosError} from "axios";
import {useAuthStore} from "../../store/auth/useAuthStore";


export const LoginForm: FC = () => {
  const {userId, setUserId} = useAuthStore()
  // const [userId, setUserId] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [isError, setIsError] = useState<boolean>(false)
  const navigate = useNavigate()

  const onLogin = () => {
    if (userId === "" || password === "") {
      setIsError(true)
      return
    }
    login({id: userId, password: password}).then(() => navigate("/")).catch(
      reason => {
        if (isAxiosError(reason)) {
          if (reason.response?.status === 403 || reason.response?.status === 401|| reason.response?.status === 404|| reason.response?.status === 400) {
            setIsError(true)
          } else throw reason
        } else throw reason
      }
    )

  }
  const onUserIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setUserId(e.target.value)
  }

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setPassword(e.target.value)
  }

  const onEnterEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onLogin()
    }
  }

  return (
    <div className="w-[15%]">
      <img src={WhiteLogo} alt="" className="mb-4"/>
      <input placeholder="Enter ID" onChange={onUserIdChange} value={userId} autoComplete="off"
             className={ `${isError?"border-tdplmaroon":"border-white"}`+
               " text-white placeholder:text-white rounded-lg border-opacity-60" +
               " border-2 w-full h-[2rem] pl-2 my-2 placeholder:text-[1rem]"
             } style={{background: "transparent"}} onKeyDown={onEnterEvent}
      />
      <input placeholder="Password" type="password" onChange={onPasswordChange} value={password} autoComplete="off"
             className={ `${isError?"border-tdplmaroon":"border-white"}`+
               " text-white placeholder:text-white rounded-lg border-opacity-60" +
               " border-2 w-full h-[2rem] pl-2 my-2 placeholder:text-[1rem]"
             } style={{background: "transparent"}} onKeyDown={onEnterEvent}
      />
      <Button className="w-full h-[2rem] my-2 text-white text-[1rem] font-bold bg-tdplplum bg-opacity-60 rounded-lg"
              onClick={onLogin}
      >
        Login
      </Button>
    </div>
  )
}