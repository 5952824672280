import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

export type useAuthState = {
  token: string | null
  refreshToken: string | null
  userId: string
}

export type useAuthAction = {
  login : (token: string, refreshToken: string) => void,
  refresh: (token: string) => void,
  logout: () => void,
  setUserId: (userId: string) => void
}

export const useAuthStore = create(
  persist<useAuthState&useAuthAction>(
    (set, get) => ({
      token: null,
      refreshToken: null,
      userId: "",
      login: (token, refreshToken) => {
        set(() => ({token: token, refreshToken: refreshToken}))
      },
      refresh: (token) => {
        set(() => ({token: token}))
      },
      logout: () => {set(()=>({token: null, refreshToken: null, userId: ""}))},
      setUserId: (userId) => {
        set(() => ({userId: userId}))
      }
    }),
    {
      name:"auth-storage",
      storage: createJSONStorage(() => localStorage)
    }
  )
)