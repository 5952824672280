import {FC, useEffect} from "react";
import {GaugeCard} from "./cards/GaugeCard";
import {SpecCard} from "./cards/SpecCard";
import {MemoCard} from "./cards/MemoCard";
import {GraphCard} from "./cards/GraphCard";
import {DistCard} from "./cards/DistributionCard";
import {useDetailSettings} from "../../hooks/tankInfo/useDetailSettings";
import {CardNameLiteral} from "../../types/apiResponse/tankInfoSettingTypes";

export type TankInfoCardContainerProps = {
  tankId: string
  title: "Water" | "Fish" | "Tank",
  cardNames: CardNameLiteral[]
}

export const TankInfoCardContainer: FC<TankInfoCardContainerProps> = (
  {
    tankId,
    title,
    cardNames
  }
) => {
  const {data, refetch} = useDetailSettings(title)
  const detailSettings = data

  useEffect(() => {
    refetch().then()
  }, [cardNames]);

  return (
    <>
    {cardNames.length !== 0?  
    <div className="my-[40px]">
      <div className="font-bold text-[1.25rem] text-tdplindigo mb-[7.5px]">{title}</div>
      <div className="flex flex-row flex-wrap gap-[15px]">
        {detailSettings?.map((detailSetting, index) => {
          if (detailSetting.gaugeSetting && detailSetting.cardType==="gauge" && cardNames.includes(detailSetting.cardName)) {
            return <GaugeCard
              key={index}
              tankId={tankId}
              cardName={detailSetting.cardName}
              gaugeSetting={detailSetting.gaugeSetting}
            />
          } else if (detailSetting.specSetting && detailSetting.cardType==="spec" && cardNames.includes(detailSetting.cardName)) {
            return <SpecCard
              key={index}
              tankId={tankId}
              cardName={detailSetting.cardName}
              specSetting={detailSetting.specSetting}
            />
          } else if (detailSetting.graphSetting && detailSetting.cardType==="graph" && cardNames.includes(detailSetting.cardName)) {
            return <GraphCard
              key={index}
              tankId={tankId}
              cardName={detailSetting.cardName}
              graphSetting={detailSetting.graphSetting}
            />
          } else if (detailSetting.cardType==="distribution" && cardNames.includes(detailSetting.cardName)) {
            return <DistCard
              key={index}
              tankId={tankId}
              cardName={detailSetting.cardName}
            />
          } else if (detailSetting.cardType === "memo" && cardNames.includes(detailSetting.cardName)) {
            return <MemoCard tankId={tankId} key={index}/>
          } else return null
        })}
      </div>
    </div>
    : <></>
  }
  </>
  )
}

export default TankInfoCardContainer