import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

export const useLayoutCurrent = () => {
  const location = useLocation()
  const [activeButton, setActiveButton] = useState<string>("Home")

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setActiveButton("Home")
        break
      case "/live":
        setActiveButton("Live")
        break
      case "/chart":
        setActiveButton("Chart")
        break
    }

  }, [location, setActiveButton]);

  return {activeButton}
}